import React, {FC, useEffect, useState} from "react";
import {Circle, MapContainer, Marker, Polyline, Popup, ScaleControl, TileLayer, Tooltip} from 'react-leaflet'
import {
    Box, Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormLabel,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Radio,
    RadioGroup, Slider,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import {PointInfo, Trace, TraceInfo} from "types";
import {useAuth, useAuthDispatch} from "./AuthContext";
import dayjs from "dayjs";
import {icon, LatLngTuple} from "leaflet";


const MapView: FC = () => {

    const [blinkStatus, setBlinkStatus] = useState<"success" | "error">(null);
    const [updating, setUpdating] = useState(false)
    const [traces, setTraces] = useState<Trace[]>();
    const [selectedTrace, setSelectedTrace] = useState<Trace>();
    const [traceInfo, setTraceInfo] = useState<TraceInfo>();

    const [showDetections, setShowDetections] = useState(false)
    const [pointIndex, setPointIndex] = useState(0)

    const [updatingTrace, setUpdatingTrace] = useState(false)
    const [recalcTraces, setRecalcTraces] = useState(false)
    const [calculateMethod, setCalculateMethod] = useState('FixedDLinear');
    const [distanceParam, setDistanceParam] = useState(3500)

    const auth = useAuth();
    const authDispatch = useAuthDispatch();

    const calculateMethods = [
        "FixedDLinear",
        // "CalculateDLinear",
        "FixedGiperbol",
    ]

    useEffect(() => {
        setBlinkStatus(null);
        setUpdating(true)
        fetch(`/api/trace`, {
            credentials: 'include',
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    authDispatch({type: 'fail'})
                }
            })
            .then(data => {
                setTraces(data);
                setBlinkStatus("success")
                setUpdating(false)
                setTimeout(() => setBlinkStatus(null), 300)
            })
            .catch(reason => {
                console.log(`api error: ${reason}`)
                setBlinkStatus("error")
                setUpdating(false)
                setTimeout(() => setBlinkStatus(null), 300)
            })
        const timer = setInterval(() => {
            setBlinkStatus(null)
            setUpdating(true)
            fetch(`/api/trace`)
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                })
                .then(data => {
                    setTraces(data.filter((t) => t.valid))
                    setBlinkStatus("success")
                    setUpdating(false)
                    setTimeout(() => setBlinkStatus(null), 300)
                })
                .catch(reason => {
                    console.log(`api error: ${reason}`)
                    setBlinkStatus("error")
                    setUpdating(false)
                    setTimeout(() => setBlinkStatus(null), 300)
                })
        }, 30000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    const recalcAllTraces = () => {
        setUpdatingTrace(true)
        fetch(`/api/trace?recalculate`, {
            credentials: 'include',
            method: 'POST',
        }).finally(() => {
            setUpdatingTrace(false)
        })
    }

    const recalcTrace = (trace) => {
        setUpdatingTrace(true)
        fetch(`/api/trace/${trace.id}?info&method=${calculateMethod}&distanceParam=${distanceParam}`, {
            credentials: 'include',
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    authDispatch({type: 'fail'})
                }
            })
            .then(data => {
                setSelectedTrace(data)
                setTraceInfo(data)
                setUpdatingTrace(false)
            })
            .finally(() => {
                setUpdatingTrace(false)
            })
    }

    useEffect(() => {
        if (calculateMethod === 'FixedDLinear') {
            setDistanceParam(3500);
        } else if (calculateMethod === 'FixedGiperbol') {
            setDistanceParam(1000000)
        }
        recalcTraces && selectedTrace && recalcTrace(selectedTrace);
    }, [calculateMethod]);

    useEffect(() => {
        setPointIndex(0)
    }, [traceInfo]);

    useEffect(() => {
    }, [traces]);

    const formatDates = (trace: Trace) => {
        const dateFrom = dayjs(trace.dateTimeFrom)
        const dateTo = dayjs(trace.dateTimeTo)
        if (trace.points.length === 1) {
            return dateFrom.format('DD MMM HH:mm');
        }
        const diffHours = dateTo.diff(dateFrom, 'hour')
        const diffMinutes = dateTo.diff(dateFrom, 'minute') % 60
        const diffSeconds = dateTo.diff(dateFrom, 'second') % 60
        const diff = `${diffHours.toString().padStart(2, "0")}:${diffMinutes.toString().padStart(2, "0")}:${diffSeconds.toString().padStart(2, "0")}`
        return `${dateFrom.format('DD MMM HH:mm')} (${diff})`
    }


    return (
        <Box height={1}>
            <Stack borderBottom={1} p={1}
                   borderColor={'divider'} spacing={4}
                   flexDirection={"row"}
                   alignItems={'baseline'}>
                <Typography variant={"h6"}>Обстановка</Typography>
            </Stack>
            <Grid container height={'calc(100% - 44px)'}>
                <Grid item xs={12} height={1}>
                    <MapContainer center={[48.688071, 38.022725]} zoom={11}
                                  style={{height: "50%"}}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <ScaleControl metric imperial={false}/>
                        {selectedTrace &&
                            <>
                                {selectedTrace.points.length > 2 &&
                                    <Marker position={selectedTrace.points[0]} icon={icon({
                                        iconUrl: 'icons/dot.png',
                                        iconAnchor: [8, 8]
                                    })}/>}
                                <Marker position={selectedTrace.points[selectedTrace.points.length - 1]}
                                        icon={icon({iconUrl: 'icons/drone.png', iconAnchor: [8, 8]})}/>
                                {!showDetections && selectedTrace.points.length > 2 && selectedTrace.points.slice(1, -1).map(point => (
                                    <Marker position={point}
                                            icon={icon({iconUrl: 'icons/dot-green.png', iconAnchor: [6, 6]})}/>
                                ))}
                                <Polyline key={selectedTrace.id} pathOptions={{color: 'green', lineJoin: 'round'}}
                                          positions={selectedTrace.points}>
                                    <Popup>{selectedTrace.type} {selectedTrace.freqs.join(',')}<br/>{formatDates(selectedTrace)}
                                    </Popup>
                                </Polyline>
                                {showDetections && traceInfo?.pointInfos[pointIndex] && (
                                    <Marker key={`${traceInfo?.points[pointIndex]}`}
                                            position={traceInfo?.points[pointIndex]}
                                            icon={icon({iconUrl: 'icons/dot-green.png', iconAnchor: [6, 6]})}/>
                                )}
                                {showDetections && traceInfo?.pointInfos[pointIndex]?.detectionInfos?.map((detectionInfo) => (
                                    <>
                                        <Circle key={`${detectionInfo.lat} ${detectionInfo.lon} ${detectionInfo.distance}`}
                                                center={[detectionInfo.lat, detectionInfo.lon]}
                                                color={traceInfo?.pointInfos[pointIndex]?.valid ? 'blue' : 'orange'}
                                                radius={detectionInfo.distance}>
                                            <Tooltip>{`Zov: ${detectionInfo.power}`}<br/>{`Расст: ${detectionInfo.distance} м`}
                                            </Tooltip>
                                        </Circle>
                                    </>
                                ))}
                            </>
                        }
                    </MapContainer>
                    <Box p={1}>
                        <Typography style={{fontSize: 'medium'}}>Пролёты с выявленным местоположением</Typography>
                    </Box>
                    <Divider/>
                    <Stack sx={{height: 'calc(50% - 60px)'}} flexDirection={'row'}>
                        <Box flex={1}>
                            {updating && <LinearProgress/>}
                            <List sx={{height: 1, overflow: 'scroll'}}>
                                {traces && traces.map(trace =>
                                    <ListItem key={trace.id}
                                              disablePadding
                                              divider>
                                        <ListItemButton
                                            selected={trace.id === selectedTrace?.id}
                                            disableTouchRipple
                                            onClick={() => recalcTraces ? recalcTrace(trace) : setSelectedTrace(trace)}>
                                            <ListItemText>
                                                <span>{trace.type} {trace.freqs.join(',')} - {formatDates(trace)} {`(${trace.points.length} точек)`} </span>
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                )}
                            </List>
                        </Box>
                        {auth === 'admin' &&
                            <Box p={2} flex={1} sx={{height: 1, overflow: 'scroll'}}>
                                <Stack>
                                    {updatingTrace && <LinearProgress/>}
                                    <Stack direction={'row'}
                                           gap={2}>
                                        <FormControlLabel control={<Checkbox value={recalcTraces}
                                                                             onChange={e => setRecalcTraces(e.target.checked)}/>}
                                                          label={'Пересчет пролётов налету'}/>
                                        <Button onClick={recalcAllTraces}>Пересчитать все</Button>
                                    </Stack>
                                    <Stack display={recalcTraces ? 'inherit' : 'none'}>
                                        <Stack>
                                            <FormLabel>Метод расчета</FormLabel>
                                            <RadioGroup
                                                value={calculateMethod}
                                                onChange={(e, v) => setCalculateMethod(v)}
                                            >
                                                {calculateMethods.map(cm => (
                                                    <FormControlLabel key={cm} value={cm} control={<Radio/>}
                                                                      label={cm}/>
                                                ))}
                                            </RadioGroup>
                                        </Stack>
                                        <Stack
                                            display={calculateMethod === 'FixedDLinear' || calculateMethod === 'FixedGiperbol' ? 'inherit' : 'none'}
                                            direction={"row"} alignItems={'center'} gap={2}>
                                            <Typography>{`Параметр расчета дальности:`}</Typography>
                                            <TextField size={"small"} type={'number'}
                                                       value={distanceParam}
                                                       onChange={e => setDistanceParam(Number.parseInt(e.target.value))}/>
                                        </Stack>
                                        <FormControlLabel control={<Checkbox value={showDetections}
                                                                             onChange={(e) => setShowDetections(e.target.checked)}/>}
                                                          label={'Отображать круги'}/>
                                        {showDetections && traceInfo?.pointInfos.length > 1 &&
                                            <>
                                                <Typography>{`Точки:`}</Typography>
                                                <Slider
                                                    sx={{width: 0.5}}
                                                    value={pointIndex}
                                                    onChange={(e, v) => setPointIndex(v[0] || v)}
                                                    min={0}
                                                    marks={[...Array(selectedTrace.points.length).fill(0)].map((i, index) => ({
                                                        value: index,
                                                        label: index + 1
                                                    }))}
                                                    max={selectedTrace.points.length - 1}
                                                />
                                            </>
                                        }
                                    </Stack>
                                </Stack>
                            </Box>
                        }
                    </Stack>

                </Grid>
            </Grid>

        </Box>
    )
}

export default MapView
