import * as React from 'react';
import {FC} from 'react';
import {createTheme, ThemeProvider} from "@mui/material";
import './App.css';
import AppView from "./AppView";
import {AuthProvider} from "./AuthContext";
import dayjs from "dayjs";
import "dayjs/locale/ru";

const theme = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 12,
    },
    components: {
        MuiOutlinedInput: {
            defaultProps: {
                size: 'small'
            }
        },
        MuiButton: {
            defaultProps: {
                variant: "contained"
            }
        },
        MuiFormControl: {
            defaultProps: {
                sx: {marginTop: 1}
            }
        }
    }
});

dayjs.locale('ru')

const App: FC = () => {

    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <AppView/>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
